// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { graphql } from 'gatsby';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Textfit } from 'react-textfit';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './404.module.scss';


const NotFoundPage = ( { data, location } ) =>  {
	const { title, contact } = data.site.siteMetadata;

	return (
		<Layout location={location} title={title} is404={true} contactInfo={contact}>
			<SEO title="404: Not Found" />
			<h1 className={styles.title}>
				<svg className={styles.svg} viewBox="0 0 1083 456">
					<path d="M554.796 17.929c99.921 0 147.503 98.732 147.503 201.627 0 111.222-55.909 226.608-163.562 226.608-107.654 0-159.399-99.327-159.399-202.222 0-111.223 60.072-226.013 175.458-226.013zm527.403 249.94c0 13.68-45.203 27.36-102.3 34.497-14.87 98.138-35.687 152.857-52.34 152.857-15.465 0-27.955-45.798-27.955-148.098-77.915-.595-151.072-18.438-151.072-68.4 0-46.986 63.64-121.927 248.615-238.502v30.333c0 80.294-3.569 151.072-10.111 210.549 68.398 5.948 95.163 16.059 95.163 26.765zm-748.214 0c0 13.68-45.202 27.36-102.3 34.497-14.87 98.138-35.687 152.857-52.34 152.857-15.464 0-27.955-45.798-27.955-148.098C73.475 306.53.32 288.687.32 238.725.32 191.739 63.96 116.798 248.934.223v30.333c0 80.294-3.569 151.072-10.111 210.549 68.398 5.948 95.163 16.059 95.163 26.765z" fill="#fff" fill-rule="evenodd"/>
				</svg>
				<span className={styles.hideVisually}>404</span>
			</h1>
			<p className={styles.copy}>
				<Textfit mode="single">This page does not exist</Textfit>
			</p>
		</Layout>
	);
};

export default NotFoundPage;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				contact {
					email
					phone
				}
			}
		}
	}
`;
